<template>
  <page-container class="about-page-container">
    <div class="back-item" @click="$router.back()"><van-icon name="arrow-left"/>返回</div>

    <div class="about-container">
      <van-image class="logo-img" width="38.2%" src="logo.jpg"/>
      <p class="version">版本 1.0.1</p>
    </div>

    <footer>
      <p>珠海优之康品互联网有限公司版权所有</p>
      <p>Copyright 2021-{{ new Date().getFullYear() }} All Rights Reserved</p>
    </footer>
  </page-container>
</template>

<script>
export default {
  name: 'about',
};
</script>

<style lang="less" scoped>
.about-page-container {
  background-color: #fff;

  .back-item {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    padding: 0 16px;
    height: 46px;
    font-size: 16px;
    color: #666;

    .van-icon-arrow-left {
      margin-right: 4px;
    }
  }

  .about-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    z-index: 1;
    top: 38.2%;
    width: 100%;
    transform: translateY(-50%);
  }

  footer {
    position: absolute;
    z-index: 1;
    bottom: 12px;
    width: 100%;
    line-height: 1.4;
    text-align: center;
    font-size: 12px;
    color: #999;

    p {
      margin: 0;
    }
  }
}

.logo-img {
  border-radius: 24px;
  overflow: hidden;
}

.logo-text {
  margin-top: 1em;
  padding: 0 50px;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: bolder;
  color: #000;
}

.version {
  margin-top: .3em;
  font-size: 14px;
  color: #999;
}

</style>
